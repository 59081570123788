.singlebanner{
  position: relative;
  width: 100%;
  height: 50vh;
  /* border: 10px black solid; */
  overflow: hidden;
}

.singlebanner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.singlebanner .bannerimgfilter{
  background-color: rgba(0, 0, 0, 0.333);
  width: 100%;
  height: 100%;
  position: absolute;
}
.singlebanner .bannerheading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

}

.singlebanner .bannerheading h1{
  color: azure;
  font-size: 50px;
  font-weight: 100;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}