.allproducts{
  display: flex;
  flex-direction: column;
  width: 78%;
}
.allproducts h1{
  font-size: 30px;
  text-align: center;

}

.allproducts .products{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 950px)
{
    .allproducts{
        width: 60%;
    }
    
}



@media (max-width: 650px)
{
    .allproducts{
        width: 100%;
    }
    
    
}