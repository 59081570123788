.yourorders {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  padding: 20px;
  position: relative;
  /* overflow: hidden; */
}


.yourorderstable {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}


.yourorderstable  thead tr {
  background-color: var(--col1);
  color: #fff;
  border: 1px solid #ddd;
  padding: .35em;
}
.yourorderstable tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: .35em;
}


.yourorderstable  th,
.yourorderstable  td {
  text-align: center;
}

.yourorderstable tr th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 10px 0px;
}


.yourorderstable tr td {
  padding: 5px 20px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  color: grey;
}


.yourorderstable tr td button {
  padding: 5px 20px;
  margin: 0px;
  font-size: 16px;
}

.yourorderstable tr td .greendot {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
}

.yourorderstable tr td div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.yourorderstable tr td .yellowdot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: orange;
}

.yourorderstable tr td .reddot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(255, 81, 81);
}


@media screen and (max-width: 1200px) {
  
  .yourorderstable thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }
  .yourorderstable tr {
      display: block;
      margin-bottom: .625em;
      border: none;
  }

  .yourorderstable tr td {
      border: none;
      border-radius: 0px;
      display: block;
      font-size: .8em;
      text-align: right;
      display: flex;
      justify-content: space-between;
  }

  .yourorderstable td::before{
      content:attr(data-label);
  }
}