h2 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 200;
}

.card-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.card-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  width: 200px;
}

.card a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.card a:hover {
  color: var(--col1);
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .card {
    width: 180px; /* Slightly smaller width for medium screens */
  }
}

@media (max-width: 992px) {
  .card {
    width: 160px; /* Smaller width for tablets */
  }
}

@media (max-width: 768px) {
  .card {
    width: 140px; /* Smaller width for large phones */
    padding: 20px; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 576px) {
  .card {
    width: 100%; /* Full width for small phones */
    padding: 15px; /* Further reduce padding */
  }
  .card-grid {
    gap: 10px; /* Reduce gap between cards */
  }
}
