.homecategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}
.homecategories .containers {
  width: 24%;
  width: 280px;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* border: 1px solid black; */
  margin: 10px;
}

.homecategories .containers img {
  width: 50%;
  height: 50%;
  object-fit: cover;
}
.homecategories .containers .contents {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homecategories .containers .contents{
  display: flex;
  flex-direction: column;
}

.homecategories .containers .contents h1 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: auto;
  text-align: center;
}

.homecategories .containers .contents p {
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  padding: 0px;
}

.homecategories .containers {
  background-color: rgba(165, 42, 42, 0.631);
  color:#eee2c4;
} 
