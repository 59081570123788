.product{
  display: flex;
  width: 250px;
  height: 300px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  position: relative;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.product .s1{
  height: 65%;
  width: 100%;
  overflow: hidden;
}

.product .s1 img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
  /* overflow: hidden; */
}
.product .s1 img:hover{
  scale: 1.1;
}
.product .s2{
  height: 20%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content:center ;
}

.product h3{
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  width: 90%;

}
.product h3 span{
  text-decoration: line-through;
  font-size: 15px;
  color: gray;
  margin-left: 5px;
}

.product .s2 p{
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  width: 90%;
}




.product .s3{
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
}

.product .s3 p{
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
  padding: 5px 0px;
  border-radius: 5px;
  color: #5b5b5b ;
  width: 90%;
}

.product .addbtn{
  position: absolute;
  margin-top: 60px;
  top: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product .addbtn svg{
  width: 28px;
  height: 28px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  padding: 5px;
  color: var(--col2);
  transition: all 0.5s ease;
}
.product .addbtn svg:hover{
  background-color: var(--col1);
  color: white;
  transform: scale(1.2);
  cursor: pointer;
}

.product .addbtn .qty{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;
  width: 100%;
  background-color:white ;
  border-radius: 5px;
  box-shadow:0px 0px 10px 0px rgba(0,0,0,0.2) ;
}

.product .addbtn .qty button{
  width: 30px;
  height: 30px;
  border: none;
  background-color: #E8DFCA;
  color: #4F6F52;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.product .addbtn .qty p{
  font-size: 20px;
  font-weight: 500;
  color: var(--col1);
  margin: 0px;
  padding: 0px;
}
.product .addtocart{
  border: none;
  background-color: var(--col1);
  color: white;
  padding: 5px;
  border-radius: 5px;
}