.authpage{
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.authcont{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80%;
  height: 80vh;
  box-shadow:  0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 10%;
  background-color: rgba(0, 0, 0, 0.798);
}

.authcont img{
  width: 50%;
  height: 100%;
  object-fit: cover;
}


.authform{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  gap: 5px;
}

.authcont .formgroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin: 10px 0px;
}


.authform h1{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 50px;
  font-weight: 500;
  color: brown;
}
.authform label{
  font-size: 17px;
  font-weight: 500;
  color: rgb(255, 255, 255);
}


.authform input{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: rgba(255, 255, 255, 0.042);
}


.authform button{
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: brown;
  cursor: pointer;
}
.authform button:hover{
  background-color: white;
  color: brown;
}

.authform p{
  font-size: 20px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  text-align: right;
}
.authform p:hover{
  color: gold;
  cursor: pointer;
}
.authform .or{
  text-align: center;
  margin: 10px 0px;
  padding: 0px;
  font-size: 20px;
  color: white;
}
.form-group-row{
  display: flex;
  gap: 10px;
}
@media (max-width: 1500px){
  .form-group-row{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
  }
}
@media (max-width: 1100px){
  .authcont{
      flex-direction: column;
      /* height: 100%; */
      width: 80%;
      margin: 0px;
      margin-top: 10%;
      margin-bottom: 10%;
      align-self: center;
      overflow: scroll;
      gap: 0px;
  }
  .authcont img{
      width: 100%;
      height: 40%;
  }
  .authform{
      width: 100%;
      margin: 0px;
      padding: 10px 20px;
      gap: 0px;
  }

  .authform  label{
      font-size: 15px;
  }
  .authform input{
      font-size: 15px;
      margin: 0px;
  }
  .authform button{
      font-size: 18px;
      padding: 10px;
  }
  .authform h1{
      font-size: 35px;
      text-align: center;
  }

   .authform .formgroup{
      margin: 5px 0px;
  }
  .authform p, .authform .or{
      font-size: 15px;
      margin: 10px 0px;
  }
 
}


@media (max-width: 500px){
  .authcont{
      flex-direction: column;
      height: 100%;
      width: 97%;
      margin: 0px;
      margin-top: 10%;
      margin-bottom: 10%;
      align-self: center;
  }
  .authcont img{
      width: 100%;
      height: 30%;
  }
  .authform{
      width: 100%;
  }
}