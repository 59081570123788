/*======================
    404 page
=======================*/
/* body {
  display: flex; 
  justify-content: center;
  /* display: grid;
  place-items: center;
  height: 100vh; */
  /* margin-left: auto;
  align-items: center;
  height: 100vh;
  width:70vw ;
  margin: 0; */
/* } */ 

.container {
  display: flex; 
  justify-content: center;
  margin-left: auto;
  align-items: center;
  margin: 0;
  margin-top: 20px;
  padding: 10px;
  max-width: 1000px;
  width: 100%;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  /* height: 70vh; */
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: auto;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: brown;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

/* Responsive adjustments for screens 769px and below */
@media (max-width: 768px) {
  .container {
    margin-top: 10px;
    padding: 5px;
  }

  .four_zero_four_bg {
    height: 300px;
  }

  .four_zero_four_bg h1 {
    font-size: 60px;
  }

  .four_zero_four_bg h3 {
    font-size: 40px;
  }

  .link_404 {
    padding: 8px 16px;
    font-size: 14px;
  }

  .contant_box_404 {
    margin-top: -30px;
  }
}
