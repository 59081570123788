html, body, #root {
  height: 100%;
  margin: 0;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.min-h-screen {
  min-height: 100vh;
}

.flex-grow {
  flex: 1;
}

.mt-auto {
  margin-top: auto;
}
