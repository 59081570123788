.legalnotice{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legalnoticein{
  margin: 20px;
}

.legalnoticein h2{
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.legalnoticein p{
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}